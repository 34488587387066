import "./TournamentsTable.scss";

import React, { useEffect, useRef, useState } from "react";
import logo from "./../../assets/images/right-arrow-svgrepo-com.svg";
import zerogif from "./../../assets/images/0.gif";
import waitingImg from "./../../assets/images/account-icon.png";
import { Button, Col, Image, Modal, Row, Form, Table } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import BannerService from "../../api/services/BannerService";
import TournamentService from "../../api/services/TournamentService";
import AnnouncementService from "../../api/services/AnnouncementService";
import PlayerList from "../PlayerList/PlayerList";
import WaitingList from "../../components/WaitingList/WaitingList";
import TournamentsStructure from "../TournamentsStructure/TournamentsStructure";
import moment from "moment";
import SettingService from "../../api/services/SettingService";
import "./TournamentsTableMobile.scss";


const TournamentsTable = (props) => {
  const { i18n, t } = useTranslation();
  const [show, setShow] = useState(false);
  const [showannounce, setShowannounce] = useState(false);
  const [announce, setannounce] = useState(false);
  const [saveMessage, setSaveMessage] = useState("");
  const [Error, setError] = useState();
  const intervalRef = useRef(null);

  const handleClose = () => setShow(false);

  const handleShow = () => setShow(true);
  const handleannounceclose = () => setShowannounce(false);
  const [confirmCancle, setConfirmCancle] = useState(false); 
  const handleconfirmcancleclose = () => setConfirmCancle(false);
  const handleconfirmcancleOpen = () => setConfirmCancle(true);
  const [confirmCancleId, setConfirmCancleId] = useState("");


  const [confirmCancleWait, setConfirmCancleWait] = useState(false); 
  const handleconfirmcanclecloseWait = () => setConfirmCancleWait(false);
  const handleconfirmcancleOpenWait = () => setConfirmCancleWait(true);
  const [confirmCancleIdWait, setConfirmCancleIdWait] = useState("");


  const [tournamentPlayerDetails, setTournamentPlayerDetails] = useState([]);
  const [tournamentAnonymousList, setTournamentAnonymousList] = useState([]);
  const [tournamentWaitingDetail, setTournamentWaitingDetail] = useState([]);
  const [tournamentStructureDetails, setTournamentStructureDetails] = useState(
    [],
  );
  const [tournamentDetails, setTournamentDetails] = useState([]);
  const [tournamentSlug, setTournamentSlug] = useState("");
  const [tournamentRoomSlug, setTournamentRoomSlug] = useState('');
  const [tournamentId, setTournamentId] = useState("");
  const [announceLateTime, setAnnounceLateTime] = useState("");
  const [premiumIds, setPremiumIds] = useState([]);
  const [isBreakTitle, setIsBreakTitle] = useState("");
  const navigate = useNavigate();
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [topbanners, setTopBanner] = useState([]);
  const [topbannersSettings, setTopBannerSetting] = useState([]);
  const [bottomBannerDesktopStart, setBottomBannerDesktopStart] = useState(0);
  const [bottomBannerDesktopInterval, setBottomBannerDesktopInterval] = useState(10);
  const [bottomBannerMobileStart, setBottomBannerMobileStart] = useState(0);
  const [bottomBannerMobileInterval, setBottomBannerMobileInterval] = useState(5);
  var imageUrl = process.env.REACT_APP_ROOM_IMAGE_URL;

  const [anonymousPlayerLength, SetAnonymousPlayerLength] = useState(0);
  const handleCloseRedirect = () => {
    setShow(false);
  };

  function getProgress(registeredPlayers, totalPlayers) {
    let className = "success";
    let playersProgress = registeredPlayers / totalPlayers;

    if (playersProgress <= 0.6) className = "success"; //green
    else if (playersProgress < 1) {
      className = "warning"; //Orange
    } else {
      className = "error"; // Red
    }
    return "text-start players-progress players-progress--" + className;
  }

  function getProgressWidth(registeredPlayers, totalPlayers) {
    return (registeredPlayers * 100) / totalPlayers + "%";
  }

  var adminlogin = JSON.parse(localStorage.getItem("adminuser"));
  const handleTableApi = async (id, slug, roomSlug) => {
    try {
      setTournamentSlug(slug);
      setTournamentRoomSlug(roomSlug);
      let responseData = await TournamentService.table(id).json();
      setTournamentDetails(responseData.data);
      handleShow();
      setTournamentStructureDetails(responseData.data.structure);

      setTournamentPlayerDetails(
        responseData.data?.players?.registered?.data || [],
      );
      setTournamentWaitingDetail(
        responseData.data?.players?.waiting?.data || [],
      );

      let anonymousList =
        responseData.data.players &&
        responseData.data.players.registered.data.filter(function (item) {
          return item.displayoption === "anonymous";
        });

      setTournamentAnonymousList(
        anonymousList.length === 0 ? "" : anonymousList,
      );
      let description =
        responseData.data.players &&
        responseData.data.players.registered.data.filter(function (item) {
          return item.displayoption === "anonymous";
        });
      SetAnonymousPlayerLength(description.length);
    } catch (error) {
      // loading(false);
      // Handle API errors
    }
    // handleShow()
  };
  const tournamentRegistration = async (id) => {

    const responseData = await TournamentService.registration({ id }).json();

    if (responseData.status === true) {
      const tournament = props.data.find(item => item.id === id);

      if (tournament.players.registered + tournament.detail.reservedplayers >= tournament.detail.maxplayers) {
        tournament.iswaiting = true;
        tournament.players.waiting += 1;
      } else {
        tournament.isuser = true;
        tournament.players.registered += 1;
      }

      props.parentCallback([...props.data]);
    }
  };
  const tournamentCancelRegistration = async () => {

    const id = confirmCancleId

    const responseData = await TournamentService.cancelRegistration({ id }).json();

    if (responseData.status === true) {
      const tournament = props.data.find(item => item.id === confirmCancleId);
      tournament.isuser = false;
      tournament.players.registered -= 1;

      props.parentCallback([...props.data]);
      setConfirmCancle(false)
      setConfirmCancleId("")
    }
  };
  const tournamentCancelRegistrationFromWaiting = async (id) => {
    let responseData = await TournamentService.cancelRegistrationFromWaiting({ id }).json();

    if (responseData.status === true) {
      const tournament = props.data.find(item => item.id === id);
      tournament.iswaiting = false;
      tournament.players.waiting -= 1;

      props.parentCallback([...props.data]);
      setConfirmCancleIdWait("");
      setConfirmCancleWait(false)
    }
  };

  const getPremiumTournament = async () => {
    const responseData =
      await TournamentService.gettodaypremiumtournament().json();
    const ids = responseData.data.map((element) => {
      return element.tournament_id;
    });
    setPremiumIds(ids);
  };

  const getSetting = async () => {
    try {
      let responseData = await SettingService.index().json();

      setIsBreakTitle(responseData.data.break_text);
    } catch (error) {
      console.log(error);
    }
  };
  const getbanner = async () => {
    try {
      clearInterval(intervalRef.current);
      intervalRef.current = null;

      const topBannerRes = await BannerService.todayBanner(2).json();
      setTopBanner(topBannerRes.data);
      setTopBannerSetting(topBannerRes.settings);
      let rollingTime = 1000;

      if (topBannerRes.settings) {
        rollingTime = topBannerRes.settings[0].rolling_time_bottom * 1000;
      }

      intervalRef.current = setInterval(() => {
        setCurrentImageIndex(
          (prevIndex) => (prevIndex + 1) % topBannerRes.data.length,
        );
      }, rollingTime);
    } catch (error) {
      console.log(error);
    }
  };

  const getCentralBannerSetting = async () => {
    try {
      const responseData = await SettingService.siteSettings().json();
      const data = responseData.data[0];

      setBottomBannerDesktopStart(data.bottom_desktop_start);
      setBottomBannerDesktopInterval(data.bottom_desktop_interval);
      setBottomBannerMobileStart(data.bottom_mobile_start);
      setBottomBannerMobileInterval(data.bottom_mobile_interval);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getPremiumTournament();
    getSetting();
    getbanner();
    getCentralBannerSetting();

    return () => {
      clearInterval(intervalRef.current);
      intervalRef.current = null;
    };
  }, []);
  const handleannounce = (e, data) => {
    setAnnounceLateTime(data);
    setTournamentId(e);
    setShowannounce(true);
  };
  const handleannounceSubmit = async (event) => {
    event.preventDefault();

    try {
      var userData = {
        latetime: event.target.timing.value,
      };

      let responseData = await AnnouncementService.lateupdate(
        tournamentId,
        userData,
      ).json();

      if (responseData.status === true) {
        setShowannounce(false);
        setSaveMessage(responseData.message);
        setannounce(true);
      }
    } catch (error) {
      if (error.name === "HTTPError") {
        const errorJson = await error.response.json();

        setError(errorJson.message);
      }
    }
  };
  const handleannouncedelete = async (e) => {
    try {
      let responseData = await AnnouncementService.lateremove(e).json();
      setShowannounce(false);
      setSaveMessage(responseData.message);
      setannounce(true);
    } catch (error) {
      if (error.name === "HTTPError") {
        const errorJson = await error.response.json();

        setError(errorJson.message);
      }
    }
  };

  const getBannerIndex = (index, bannerStart, bannerInterval) => {
    if (topbanners.length < 2) {
      return 0;
    }

    let bannerIndex = currentImageIndex + Math.floor((index + 1 - bannerStart) / bannerInterval);
    bannerIndex = bannerIndex % topbanners.length;
    return bannerIndex;
  };

  return (
    <>
      <div className="table-container d-none d-md-block mt-5 dark-theme">
        <Table responsive>
          <thead>
            <tr>
              <th className="width-auto text-center" style={{ width: "140px" }}>
                {t("page.tournaments.tournamentstable.Date")}
              </th>
              <th className="width-auto text-nowrap">
                {t("page.tournaments.tournamentstable.Tournament Name")}
              </th>
              <th className="width-auto text-center text-nowrap">
                {t("page.tournaments.tournamentstable.Poker Room")}
              </th>
              <th className="width-auto text-center d-md-none d-lg-table-cell">
                {t("page.tournaments.tournamentstable.Canton")}
              </th>
              <th className="width-auto text-nowrap">
                {t("page.tournaments.tournamentstable.Type")}
              </th>
              <th className="width-auto text-nowrap">
                {t("page.tournaments.tournamentstable.Buy-in")}
              </th>
              <th className="width-auto text-nowrap">
                {t("page.tournaments.tournamentstable.Players")}
              </th>
              <th className="width-auto text-nowrap" colSpan={2}>
                {t("page.tournamentsdetails.Registration")}
              </th>
            </tr>
          </thead>

          <tbody>
            {props.data.map((element, index) => {
              return (
                <React.Fragment key={index}>
                  <tr key={index}>
                    <td className="align-middle">
                      <p className="tournament-date text-nowrap">
                        {/*  */}
                        {t("currentdate", {
                          date: new Date(element.detail.startday),
                        })}{" "}
                        {moment(element.detail.startday).format(
                          "DD.MM.YYYY HH:mm",
                        )}
                      </p>

                      {element.detail.lastday ? (
                        <p className="tournament-date text-nowrap">
                          {moment(element.detail.startday).format(
                            "DD.MM.YYYY",
                          ) ==
                            moment(element.detail.lastday).format(
                              "DD.MM.YYYY",
                            ) ? (
                            <></>
                          ) : (
                            <>
                              <img
                                src={logo}
                                width="40"
                                height="50"
                                alt=""
                                style={{ maxHeight: "18px" }}
                              />
                              {moment(element.detail.lastday).format(
                                "DD.MM.YYYY",
                              )}
                            </>
                          )}
                        </p>
                      ) : (
                        ""
                      )}
                      <p className="tournament-late-reg text-nowrap">
                        {element.detail.lateregformat === "time" ? (
                          <>
                            {t("page.tournamentsdetails.Late Reg")}:{" "}
                            {element.detail.lateregtime
                              ? element.detail.lateregtime.slice(0, 5)
                              : ""}
                            {/* Late Reg:  {moment(element.detail.lateregtime).format('hh:mm')} */}
                          </>
                        ) : element.detail.lateregformat === "round" ? (
                          <> {t("page.tournamentsdetails.Late Reg")}: {t("page.tournamentsdetails.Round")} {element.detail.latereground}</>
                        ) : (
                          ""
                        )}
                      </p>
                    </td>
                    <td className={
                      props.bottombannersSetting.length > "0" &&
                        props.bottombannersSetting[0].is_premium_tournament == 1
                        ? premiumIds.includes(element.id)
                          ? "premium-item align-middle"
                          : "align-middle"
                        : "align-middle"
                    }>
                      <Link to={`/tournaments/${element.room.slug}/${element.slug}`}>
                        <b>{element.title}</b>
                      </Link>
                    </td>
                    <td className="align-middle">
                      <Link
                        to={`/room/${element.room.slug}`}
                        className="align-middle text-truncate w-100 room-logo"
                      >
                        <Image
                          src={
                            element.room.detail.logo
                              ? imageUrl + element.room.detail.logo
                              : "https://api.checkraise.ch/assets/images/ap-3.png"
                          }
                          fluid
                        />
                      </Link>
                    </td>
                    <td className="text-center align-middle d-md-none d-lg-table-cell">
                      <span>
                        {element.room ? element.room.detail.canton : ""}
                      </span>
                    </td>
                    <td className="align-middle">
                      <div className="d-flex justify-content-center definition-of-games-flex">
                        {element.detail.maxreentries !== 0 ? (
                          <span className="re-entry defination-games-info-span">
                            R
                          </span>
                        ) : (
                          ""
                        )}
                        {element.detail.bounty !== 0 ? (
                          <>
                            <span className="bounty  defination-games-info-span">
                              B
                            </span>
                          </>
                        ) : (
                          ""
                        )}
                        {element.detail.ischampionship !== 0 ? (
                          <>
                            <span className=" championship-bagde me-2 v-middle"></span>
                          </>
                        ) : (
                          ""
                        )}
                      </div>
                    </td>
                    <td className="text-center align-middle">
                      {element.detail.buyin}
                      {element.detail.bounty !== 0
                        ? "+" + element.detail.bounty
                        : ""}
                      {element.detail.rake !== 0
                        ? "+" + element.detail.rake
                        : ""}
                    </td>
                    <td className="align-middle" style={{ width: "90px" }}>
                      <span
                        className={getProgress(
                          element.disable_registration
                            ? element.detail.maxplayers
                            : element.players.registered + element.detail.reservedplayers,
                          element.detail.maxplayers,
                        )}
                      >
                        <span
                          className="progress-inner d-inline-block"
                          style={{
                            width: element.disable_registration
                              ? '100%'
                              : getProgressWidth(
                                element.players.registered + element.detail.reservedplayers,
                                element.detail.maxplayers,
                              ),
                          }}
                        >
                          <span className="text-center w-100 players-position">
                            {element.disable_registration
                              ? ''
                              : <>
                                <span className="players-current">
                                  {element.players.registered + element.detail.reservedplayers} /{" "}
                                </span>
                              </>
                            }
                            <span className="players-total">
                              {element.detail.maxplayers}
                            </span>
                          </span>
                        </span>
                      </span>
                      {element.disable_registration
                        ? ''
                        : <span style={{ fontSize: 10 }}>
                          {element.players && element.players.waiting !== 0
                            ? t('page.tournamentsdetails.Waiting list') + ':' + element.players.waiting
                            : ""
                          }
                        </span>}
                    </td>

                    <td className="align-middle">
                      <Link
                        to="#"
                        // onClick={handleShow}
                        onClick={(e) =>
                          handleTableApi(element.id, element.slug, element.room.slug)
                        }
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          className="bi bi-people-fill"
                          viewBox="0 0 16 16"
                        >
                          <path
                            d="M7 14s-1 0-1-1 1-4 5-4 5 3 5 4-1 1-1 1H7Zm4-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6Zm-5.784 6A2.238 2.238 0 0 1 5 13c0-1.355.68-2.75 1.936-3.72A6.325 6.325 0 0 0 5 9c-4 0-5 3-5 4s1 1 1 1h4.216ZM4.5 8a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5Z"></path>
                        </svg>
                      </Link>
                    </td>

                    {localStorage.getItem("usertype") === "Player" ? (
                      <td className="align-middle">
                        {element.disable_registration
                          ? <p className="btn btn-sm btn-no-registration m-0">
                            {t("page.tournamentsdetails.No Registration")}
                          </p>
                          : element.is_suspend
                            ? <p className="btn btn-sm btn-suspended">
                              {t("page.tournamentsdetails.Suspended")}

                            </p>
                            : element.isuser || element.iswaiting ? (
                              <>
                                {element.players &&
                                  element.players.waiting !== 0 ? (
                                  <>
                                    <p
                                      className="btn btn-sm btn-cancel-register-tournament mb-0 me-1 mb-md-1 mb-lg-0"
                                      // onClick={(e) =>
                                      //   tournamentCancelRegistrationFromWaiting(
                                      //     element.id,
                                      //   )
                                      // }
                                      onClick={() => {
                                        handleconfirmcancleOpenWait(); // Opens the modal
                                        setConfirmCancleIdWait(element.id); // Stores the ID for the action
                                      }}


                                   
                                    >
                                      {t("page.tournamentsdetails.Cancel Registration")}
                                      <br />
                                      <span className="player-waiting-text">
                                        {t("page.tournamentsdetails.Waiting list")}  (pos {element.players.waiting})
                                      </span>
                                    </p>
                                  </>
                                ) : (
                                  <p
                                    className="btn btn-sm btn-cancel-register-tournament mb-0"
                                    // onClick={(e) =>
                                    //   tournamentCancelRegistration(element.id)
                                    // }
                                    onClick={() => {
                                      handleconfirmcancleOpen(); // Opens the modal
                                      setConfirmCancleId(element.id); // Stores the ID for the action
                                    }}

                                    
                                  >
                                    {t("page.tournamentsdetails.Cancel Registration")}

                                  </p>
                                )}

                                {/* {element.players && element.players.waiting !== 0 ? '' : */}
                                <>
                                  {element.is_late_arrival == 1 && moment(element.detail.startday).isSameOrBefore(moment().add(element.room.latearrivaldelay, 'hour')) ? (
                                    element.late === "" ? (
                                      <Button
                                        className="btn btn-sm btn-primary btn-register-tournament btn-announce d-block mt-1 m-auto"
                                        onClick={() => handleannounce(element.id)}
                                      >
                                        {t("page.tournamentsdetails.Announce my late reg")}

                                      </Button>
                                    ) : (
                                      <>
                                        <p>{t("page.tournamentsdetails.Late")}: {element.late}</p>
                                        <Link
                                          className="action-link blue-link mb-1"
                                          onClick={() =>
                                            handleannounce(element.id, element.late)
                                          }
                                        >
                                          {t("page.tournamentsdetails.Edit")}

                                        </Link>
                                        <Link
                                          className="action-link red-link mb-1"
                                          onClick={() =>
                                            handleannouncedelete(
                                              element.detail &&
                                              element.detail.tournament_id,
                                            )
                                          }
                                        >
                                          {t("page.tournamentsdetails.Delete")}

                                        </Link>
                                      </>
                                    )
                                  ) : (
                                    ""
                                  )}
                                </>
                                {/* } */}
                              </>
                            ) : element.players.registered +
                              element.detail.reservedplayers ===
                              element.detail.maxplayers ? (
                              <Button
                                className="btn btn-sm btn-primary btn-register-tournament"
                                onClick={(e) => tournamentRegistration(element.id)}
                              >
                                {t("page.tournamentsdetails.Waiting list")}
                                ({element.players.waiting})
                              </Button>
                            ) : (
                              <Button
                                className="btn btn-sm btn-primary btn-register-tournament"
                                onClick={(e) => tournamentRegistration(element.id)}
                              >
                                {t("page.tournamentsdetails.Register")}

                              </Button>
                            )}
                      </td>
                    ) : localStorage.getItem("usertype") === "Room Manager" ? (
                      element.room.user_id === props.id ? (
                        <td className="align-middle">
                          <Link
                            className="btn btn-sm btn-primary btn-register-tournament check-in-btn"
                            to={`/manager/checkin-tournament/${element.id}`}
                          >
                            {t("page.tournamentsdetails.CHECK-IN")}

                          </Link>
                        </td>
                      ) : (
                        <td></td>
                      )
                    ) : localStorage.getItem("usertype") === "Director" ? (
                      <td className="align-middle">
                        <Link
                          className="btn btn-sm btn-primary btn-register-tournament check-in-btn"
                          to={`/director/checkin/${element.id}`}
                        >
                          {t("page.tournamentsdetails.CHECK-IN")}

                        </Link>
                      </td>
                    ) : (
                      <td className="align-middle">
                        <Button
                          className="btn btn-sm btn-primary btn-register-tournament"
                          onClick={() => navigate("/login")}
                        >
                          {t("page.tournamentsdetails.Login to register")}

                        </Button>
                      </td>
                    )}
                  </tr>
                  {(index + 1 >= bottomBannerDesktopStart && (index + 1 - bottomBannerDesktopStart) % bottomBannerDesktopInterval === 0)
                    ? (
                      <tr>
                        {topbannersSettings.length > 0
                          && topbannersSettings[0].adv_bottom_banner == 1
                          && topbanners.length > 0 ? (
                          <td colSpan={9}>
                            <div className="advertising-banner text-center m-auto">

                              <a
                                // href={`https://${topbanners[getBannerIndex(index, bottomBannerDesktopStart, bottomBannerDesktopInterval)]?.url}`}
                                href={`${topbanners[getBannerIndex(index, bottomBannerDesktopStart, bottomBannerDesktopInterval)]?.url
                                  .replace(/^http:\/\//, 'https://')
                                  .replace(/^https:\/\//, 'https://')
                                  }`}
                                target="_blank"
                              >
                                <img
                                  className="image divImg"
                                  src={zerogif}
                                  alt="Changing Image"
                                  width="500"
                                  height="50"
                                  style={{
                                    backgroundImage:
                                      "url(" +
                                      process.env
                                        .REACT_APP_BANNER_IMAGE_URL +
                                      "" +
                                      topbanners[getBannerIndex(index, bottomBannerDesktopStart, bottomBannerDesktopInterval)]?.image +
                                      ")",
                                  }}
                                />
                              </a>
                            </div>
                          </td>
                        ) : (
                          <></>
                        )}
                      </tr>
                    ) : (
                      <></>
                    )}
                </React.Fragment>
              );
            })}
            {props.data.length < 1 || props.data.length < bottomBannerDesktopStart
              ? <tr>
                {topbannersSettings.length > 0
                  && topbannersSettings[0].adv_bottom_banner == 1
                  && topbanners.length > 0 ? (
                  <td colSpan={9}>
                    <div className="advertising-banner text-center m-auto">
                      <a
                        // href={`https://${topbanners[currentImageIndex].url}`}
                        href={`${topbanners[currentImageIndex]?.url
                          .replace(/^http:\/\//, 'https://')
                          .replace(/^https:\/\//, 'https://')
                          }`}
                        target="_blank"
                      >
                        <img
                          className="image divImg"
                          src={zerogif}
                          alt="Changing Image"
                          width="500"
                          height="50"
                          style={{
                            backgroundImage:
                              "url(" +
                              process.env
                                .REACT_APP_BANNER_IMAGE_URL +
                              "" +
                              topbanners[currentImageIndex].image +
                              ")",
                          }}
                        />
                      </a>
                    </div>
                  </td>
                ) : (
                  <></>
                )}
              </tr>
              : ''}
          </tbody>
        </Table>
      </div>

      {/* mobile view */}

      {/* <div className="filter-title d-block d-md-none">
        <h3><strong>{t('page.tournaments.tournamentstable.Today')}</strong></h3>
      </div>

      <div className="filter-title d-block d-md-none">
        <h3><strong>{t('page.tournaments.tournamentstable.Upcomming')} </strong></h3>
      </div> */}
      <div className="filter-title d-block d-md-none">

        {
          props.data.filter(element => moment(element.detail.startday).isSame(moment(), 'day')).length > 0
            ? <h3><strong>{t('page.tournaments.tournamentstable.Today')}</strong></h3>
            : ""
        }

        {props.data
          .filter(element => moment(element.detail.startday).isSame(moment(), 'day')) // Filter today's tournaments
          .map((element, index) => (

            <div className="table-tournaments-mobile d-block d-md-none dark-theme">
              <React.Fragment key={index}>
                <div
                  className={`tournament-new-row d-flex flex-column ${props.bottombannersSetting.length > "0" &&
                    props.bottombannersSetting[0].is_premium_tournament == 1
                    ? premiumIds.includes(element.id)
                      ? "preminum-tournament-row"
                      : ""
                    : ""
                    }`}
                >
                  <div className="d-flex">
                    <div className="tournament-title-wrap">


                      <Link
                        to={`/tournaments/${element.room.slug}/${element.slug}`}
                        className="text-truncate min-w-1 tournament-title"
                      >
                        {element.title}
                      </Link>

                      <span className="tournament-date">
                        {t("currentdate", {
                          date: new Date(element.detail.startday),
                        })}
                        {moment(element.detail.startday).format(" DD.MM.YYYY")}

                        {element.detail.lastday ? (
                          <p className="tm-date text-nowrap mb-0">
                            {moment(element.detail.startday).format("DD.MM.YYYY") ==
                              moment(element.detail.lastday).format("DD.MM.YYYY") ? (
                              <></>
                            ) : (
                              <>
                                <img
                                  src={logo}
                                  width="20"
                                  height="20"
                                  alt=""
                                  style={{ maxHeight: "18px" }}
                                />
                                {moment(element.detail.lastday).format(
                                  "DD.MM.YYYY",
                                )}
                              </>
                            )}
                          </p>
                        ) : (
                          ""
                        )}
                      </span>
                    </div>
                    <div className="tournament-logo">
                      <Link to={`/room/${element.room.slug}`}>
                        <Image
                          src={
                            element.room.detail.logo
                              ? imageUrl + element.room.detail.logo
                              : "../../assets/images/ap-3.png"
                          }
                          fluid
                        />
                      </Link>
                    </div>
                  </div>
                  <div className="d-flex tournament-new-row-bottom align-items-stretch">
                    <div className="flex-fill tm-time-late-reg d-flex justify-content-center flex-column">
                      <div class="tm-time">
                        {" "}
                        {moment(element.detail.startday).format("HH:mm")}
                      </div>
                      <p class="tm-late-reg mb-0 f-10"> {" "}
                        {element.detail.lateregformat === "time" ? (
                          // .slice(0, 5)
                          <>
                            {t("page.tournamentsdetails.Late Reg")} : {" "}
                            {element.detail.lateregtime
                              ? element.detail.lateregtime.slice(0, 5)
                              : ""}
                            {/* Late Reg:  {moment(element.detail.lateregtime).format('hh:mm')} */}
                          </>
                        ) : element.detail.lateregformat === "round" ? (
                          <> {t("page.tournamentsdetails.Late Reg")}: {t("page.tournamentsdetails.Rnd")} {element.detail.latereground}</>
                        ) : (
                          ""
                        )}</p>
                    </div>
                    <div className="position-relative d-flex  align-items-center justify-content-center flex-fill defination-games-info-middle">
                      <div className="defination-games-info-wrap">
                        {element.detail.maxreentries !== 0 ? (
                          <span className="re-entry defination-games-info-span">
                            R
                          </span>
                        ) : (
                          ""
                        )}
                        {element.detail.bounty !== 0 ? (
                          <>
                            <span className="bounty  defination-games-info-span">
                              B
                            </span>
                          </>
                        ) : (
                          ""
                        )}
                        {element.detail.ischampionship !== 0 ? (
                          <>
                            <span className=" championship-bagde me-2 v-middle"></span>
                          </>
                        ) : (
                          ""
                        )}
                      </div>
                      {element.detail.bounty !== 0 ? (
                           <>
                           <div className="d-flex  align-self-center flex-wrap px-0 text-break buyin-fsize">
                              {element.detail.buyin}
                              {element.detail.bounty !== 0
                                ? "+" + element.detail.bounty
                                : ""}
                              {element.detail.rake !== 0
                                ? "+" + element.detail.rake
                                : ""}
                            </div>
                           </>
                          ) : (
                            <div className="d-flex  align-self-center flex-wrap px-0 text-break">
                              {element.detail.buyin}
                              {element.detail.bounty !== 0
                                ? "+" + element.detail.bounty
                                : ""}
                              {element.detail.rake !== 0
                                ? "+" + element.detail.rake
                                : ""}
                            </div>
                        )}
                      
                      
                      
                    </div>
                    <div className="d-flex flex-fill text-center justify-content-center align-items-center">
                      <div className="position-relative d-inline-flex align-self-center" onClick={(e) => handleTableApi(element.id, element.slug, element.room.slug)}>
                        <span
                          className={getProgress(
                            element.disable_registration
                              ? 0
                              : element.players.registered + element.detail.reservedplayers,
                            element.disable_registration ? 0 : element.detail.maxplayers,
                          )}
                        >
                          <span
                            className="progress-inner d-inline-block"
                            style={{
                              width: element.disable_registration ? '100%' : getProgressWidth(
                                element.players.registered +
                                element.detail.reservedplayers,
                                element.detail.maxplayers,
                              ),
                            }}
                          >
                            <span className="text-center w-100 players-position">
                              {element.disable_registration
                                ? ''
                                : <span className="players-current">
                                  {element.players.registered +
                                    element.detail.reservedplayers} /{" "}
                                </span>
                              }
                              <span className="players-total">
                                {element.detail.maxplayers}
                              </span>
                            </span>
                          </span>
                        </span>
                        <span className="down-arrow" onClick={(e) => handleTableApi(element.id, element.slug, element.room.slug)}>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="#000"
                            className="bi bi-people-fill"
                            viewBox="0 0 16 16"

                          >
                            <path
                              d="M7 14s-1 0-1-1 1-4 5-4 5 3 5 4-1 1-1 1H7Zm4-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6Zm-5.784 6A2.238 2.238 0 0 1 5 13c0-1.355.68-2.75 1.936-3.72A6.325 6.325 0 0 0 5 9c-4 0-5 3-5 4s1 1 1 1h4.216ZM4.5 8a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5Z"></path>
                          </svg>
                        </span>
                      </div>
                    </div>

                    <div className="d-flex flex-wrap flex-fill text-center justify-content-center align-items-center">

                      {localStorage.getItem("usertype") === "Player" ? (

                        <div className="">

                          {element.disable_registration
                            ? <p className="btn btn-sm btn-no-registration m-0">
                              {t("page.tournamentsdetails.No Registration")}

                            </p>
                            : element.is_suspend
                              ? <p className="btn btn-sm btn-suspended">
                                {t("page.tournamentsdetails.Suspended")}

                              </p>
                              : element.isuser || element.iswaiting ? (
                                <>
                                  {element.players &&
                                    element.players.waiting !== 0 ? (
                                    <>
                                      <p
                                        className="btn btn-sm btn-cancel-register-tournament"
                                        // onClick={(e) =>
                                        //   tournamentCancelRegistrationFromWaiting(
                                        //     element.id,
                                        //   )
                                        // }
                                        onClick={() => {
                                          handleconfirmcancleOpenWait(); // Opens the modal
                                          setConfirmCancleIdWait(element.id); // Stores the ID for the action
                                        }}
  
                                      >
                                        {t('page.tournaments.tournamentstable.Unregister')} <br />
                                        <span className="player-waiting-text d-block">
                                          {t("page.tournamentsdetails.Waiting list")}    (pos {element.players.waiting})
                                        </span>
                                      </p>
                                    </>
                                  ) : (
                                    <p
                                      className="btn btn-sm btn-cancel-register-tournament"
                                      // onClick={(e) =>
                                      //   tournamentCancelRegistration(element.id)
                                      // }
                                      onClick={() => {
                                        handleconfirmcancleOpen(); // Opens the modal
                                        setConfirmCancleId(element.id); // Stores the ID for the action
                                      }}
                                    >
                                      {t('page.tournaments.tournamentstable.Unregister')}
                                    </p>
                                  )}


                                  <>
                                    {element.is_late_arrival == 1 ? (
                                      element.late === "" ? (
                                        <Button
                                          className="btn btn-sm btn-primary btn-register-tournament btn-announce"
                                          onClick={() => handleannounce(element.id)}
                                        >
                                          {t("page.tournamentsdetails.Announce my late reg")}

                                        </Button>
                                      ) : (
                                        <>
                                          <div>
                                            <p className="mb-0">Late: {element.late}</p>
                                            <Link
                                              className="action-link blue-link"
                                              onClick={() =>
                                                handleannounce(element.id, element.late)
                                              }
                                            >
                                              {t("page.tournamentsdetails.Edit")}

                                            </Link>
                                            <Link
                                              className="action-link red-link mb-1"
                                              onClick={() =>
                                                handleannouncedelete(
                                                  element.detail &&
                                                  element.detail.tournament_id,
                                                )
                                              }
                                            >
                                              {t("page.tournamentsdetails.Delete")}

                                            </Link>
                                          </div>
                                        </>
                                      )
                                    ) : (
                                      ""
                                    )}
                                  </>
                                  {/* } */}
                                </>
                              ) : element.players.registered +
                                element.detail.reservedplayers ===
                                element.detail.maxplayers ? (
                                <Button
                                  className="btn btn-sm btn-primary btn-register-tournament"
                                  onClick={(e) => tournamentRegistration(element.id)}
                                >
                                  <Image
                                    src={waitingImg}
                                    width="20"
                                    height="20"
                                    fluid
                                  /> {t("page.tournamentsdetails.Waiting list")}  ({element.players.waiting})
                                </Button>
                              ) : (
                                <Button
                                  className="btn btn-sm btn-primary btn-register-tournament"
                                  onClick={(e) => tournamentRegistration(element.id)}
                                >
                                  <Image
                                    src={waitingImg}
                                    width="20"
                                    height="20"
                                    fluid
                                  />
                                </Button>
                              )}
                        </div>
                      ) : localStorage.getItem("usertype") === "Room Manager" ? (
                        element.room.user_id === props.id ? (
                          <div className="">
                            <Link
                              className="btn btn-sm btn-primary btn-register-tournament check-in-btn"
                              to={`/manager/checkin-tournament/${element.id}`}
                            >
                              {t("page.tournamentsdetails.CHECK-IN")}

                            </Link>
                          </div>
                        ) : (
                          <div className="tm-action"></div>
                        )
                      ) : localStorage.getItem("usertype") === "Admin" ? (
                        <div className="">
                          <Button className="btn btn-sm btn-primary btn-register-tournament check-in-btn">
                            {t("page.tournamentsdetails.CHECK-IN")}
                          </Button>
                        </div>
                      ) : (
                        <div className="tm-action">
                          <Button
                            className="btn btn-sm btn-primary btn-register-tournament"
                            onClick={() => navigate("/login")}
                          >
                            {t("page.tournamentsdetails.Login to register")}

                          </Button>
                        </div>
                      )}

                    </div>
                  </div>

                </div>

              </React.Fragment>
              { /*index + 1 >= bottomBannerMobileStart && (index + 1 - bottomBannerMobileStart) % bottomBannerMobileInterval === 0 ? (
                <>
                  {topbannersSettings.length > 0
                    && topbannersSettings[0].adv_bottom_banner == 1
                    && topbanners.length > 0
                    ? (
                      <div className="advertising-banner">
                        <a

                          // href={`https://${topbanners[getBannerIndex(index, bottomBannerMobileStart, bottomBannerMobileInterval)]?.url}`}
                          href={`${topbanners[getBannerIndex(index, bottomBannerMobileStart, bottomBannerMobileInterval)]?.url
                            .replace(/^http:\/\//, 'https://')
                            .replace(/^https:\/\//, 'https://')
                            }`}
                          target="_blank"

                        >
                          <img
                            className="image divImg"
                            src={zerogif}
                            width="500"
                            height="50"
                            style={{
                              backgroundImage:
                                "url(" +
                                process.env.REACT_APP_BANNER_IMAGE_URL +
                                "" +
                                topbanners[getBannerIndex(index, bottomBannerMobileStart, bottomBannerMobileInterval)]?.image +
                                ")",
                            }}
                            alt="Changing Image"
                          />
                        </a>
                      </div>
                    ) : (
                      <></>
                    )}
                </>
              ) : (
                <></>
              ) */}
            </div>
          ))
        }
        {
          <>
          {topbannersSettings.length > 0
            && topbannersSettings[0].adv_bottom_banner == 1
            && topbanners.length > 0 ? (
            <div className="advertising-banner 1195">
              <a
                // href={`https://${topbanners[currentImageIndex].url}`}
                href={`${topbanners[currentImageIndex]?.url
                  .replace(/^http:\/\//, 'https://')
                  .replace(/^https:\/\//, 'https://')
                  }`}
                target="_blank"
              >

                <img
                  className="image divImg"
                  src={zerogif}
                  alt="Changing Image"
                  width="500"
                  height="50"
                  style={{
                    backgroundImage:
                      "url(" +
                      process.env
                        .REACT_APP_BANNER_IMAGE_URL +
                      "" +
                      topbanners[currentImageIndex].image +
                      ")",
                  }}
                />
              </a>
            </div>
          ) : (
            <>
            <div className="advertising-banner-blank 1195"></div>
            </>
          ) }
          </>
        }
        { /*props.data.length < 1 || props.data.length < bottomBannerDesktopStart
          ? <div className="advertising-banner">
            {topbannersSettings.length > 0
              && topbannersSettings[0].adv_bottom_banner == 1
              && topbanners.length > 0 ? (
              <a
                // href={`https://${topbanners[currentImageIndex].url}`}
                href={`${topbanners[currentImageIndex]?.url
                  .replace(/^http:\/\//, 'https://')
                  .replace(/^https:\/\//, 'https://')
                  }`}
                target="_blank"
              >

                <img
                  className="image divImg"
                  src={zerogif}
                  alt="Changing Image"
                  width="500"
                  height="50"
                  style={{
                    backgroundImage:
                      "url(" +
                      process.env
                        .REACT_APP_BANNER_IMAGE_URL +
                      "" +
                      topbanners[currentImageIndex].image +
                      ")",
                  }}
                />
              </a>

            ) : (
              <></>
            ) }
          </div>
          : ""
         */ }
      </div>

      <div className="filter-title d-block d-md-none">
        {
          props.data.filter(element => moment(element.detail.startday).isAfter(moment(), 'day')).length > 0
            ? <h3><strong>{t('page.tournaments.tournamentstable.Upcomming')}</strong></h3>
            : ""
        }        
        {props.data
          .filter(element => moment(element.detail.startday).isAfter(moment(), 'day')) // Filter upcoming tournaments
          .map((element, index) => (

            <div className="table-tournaments-mobile d-block d-md-none dark-theme">
              <React.Fragment key={index}>
                <div
                  className={`tournament-new-row d-flex flex-column ${props.bottombannersSetting.length > "0" &&
                    props.bottombannersSetting[0].is_premium_tournament == 1
                    ? premiumIds.includes(element.id)
                      ? "preminum-tournament-row"
                      : ""
                    : ""
                    }`}
                >
                  <div className="d-flex">
                    <div className="tournament-title-wrap">


                      <Link
                        to={`/tournaments/${element.room.slug}/${element.slug}`}
                        className="text-truncate min-w-1 tournament-title"
                      >
                        {element.title}
                      </Link>

                      <span className="tournament-date">
                        {t("currentdate", {
                          date: new Date(element.detail.startday),
                        })}
                        {moment(element.detail.startday).format(" DD.MM.YYYY")}

                        {element.detail.lastday ? (
                          <p className="tm-date text-nowrap mb-0">
                            {moment(element.detail.startday).format("DD.MM.YYYY") ==
                              moment(element.detail.lastday).format("DD.MM.YYYY") ? (
                              <></>
                            ) : (
                              <>
                                <img
                                  src={logo}
                                  width="20"
                                  height="20"
                                  alt=""
                                  style={{ maxHeight: "18px" }}
                                />
                                {moment(element.detail.lastday).format(
                                  "DD.MM.YYYY",
                                )}
                              </>
                            )}
                          </p>
                        ) : (
                          ""
                        )}
                      </span>
                    </div>
                    <div className="tournament-logo">
                      <Link to={`/room/${element.room.slug}`}>
                        <Image
                          src={
                            element.room.detail.logo
                              ? imageUrl + element.room.detail.logo
                              : "../../assets/images/ap-3.png"
                          }
                          fluid
                        />
                      </Link>
                    </div>
                  </div>
                  <div className="d-flex tournament-new-row-bottom align-items-stretch">
                    <div className="flex-fill tm-time-late-reg d-flex justify-content-center flex-column defination-games-info">
                      <div class="tm-time">
                        {" "}
                        {moment(element.detail.startday).format("HH:mm")}
                      </div>
                      <p class="tm-late-reg mb-0 f-10 "> {" "}
                        {element.detail.lateregformat === "time" ? (
                          // .slice(0, 5)
                          <>
                            {t("page.tournamentsdetails.Late Reg")}:{" "}
                            {element.detail.lateregtime
                              ? element.detail.lateregtime.slice(0, 5)
                              : ""}
                            {/* Late Reg:  {moment(element.detail.lateregtime).format('hh:mm')} */}
                          </>
                        ) : element.detail.lateregformat === "round" ? (
                          <> {t("page.tournamentsdetails.Late Reg")}: {t("page.tournamentsdetails.Rnd")} {element.detail.latereground}</>
                        ):(
                          ""
                        )}</p>
                    </div>
                    <div className="position-relative d-flex  align-items-center justify-content-center flex-fill defination-games-info-middle">
                      <div className="defination-games-info-wrap">
                        {element.detail.maxreentries !== 0 ? (
                          <span className="re-entry defination-games-info-span">
                            R
                          </span>
                        ) : (
                          ""
                        )}
                        {element.detail.bounty !== 0 ? (
                          <>
                            <span className="bounty  defination-games-info-span">
                              B
                            </span>
                          </>
                        ) : (
                          ""
                        )}
                        {element.detail.ischampionship !== 0 ? (
                          <>
                            <span className=" championship-bagde me-2 v-middle"></span>
                          </>
                        ) : (
                          ""
                        )}
                      </div>
                      {element.detail.bounty !== 0 ? (
                          
                           <div className="d-flex  align-self-center flex-wrap px-0 text-break buyin-fsize">
                              {element.detail.buyin}
                              {element.detail.bounty !== 0
                                ? "+" + element.detail.bounty
                                : ""}
                              {element.detail.rake !== 0
                                ? "+" + element.detail.rake
                                : ""}
                            </div>
                           
                          ) : (
                            <div className="d-flex  align-self-center flex-wrap px-0 text-break">
                              {element.detail.buyin}
                              {element.detail.bounty !== 0
                                ? "+" + element.detail.bounty
                                : ""}
                              {element.detail.rake !== 0
                                ? "+" + element.detail.rake
                                : ""}
                            </div>
                        )}

                    </div>
                    <div className="d-flex flex-fill text-center justify-content-center align-items-center">
                      <div className="position-relative d-inline-flex align-self-center" onClick={(e) => handleTableApi(element.id, element.slug, element.room.slug)}>
                        <span
                          className={getProgress(
                            element.disable_registration
                              ? 0
                              : element.players.registered + element.detail.reservedplayers,
                            element.disable_registration ? 0 : element.detail.maxplayers,
                          )}
                        >
                          <span
                            className="progress-inner d-inline-block"
                            style={{
                              width: element.disable_registration ? '100%' : getProgressWidth(
                                element.players.registered +
                                element.detail.reservedplayers,
                                element.detail.maxplayers,
                              ),
                            }}
                          >
                            <span className="text-center w-100 players-position">
                              {element.disable_registration
                                ? ''
                                : <span className="players-current">
                                  {element.players.registered +
                                    element.detail.reservedplayers} /{" "}
                                </span>
                              }
                              <span className="players-total">
                                {element.detail.maxplayers}
                              </span>
                            </span>
                          </span>
                        </span>
                        <span className="down-arrow" onClick={(e) => handleTableApi(element.id, element.slug, element.room.slug)}>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="#000"
                            className="bi bi-people-fill"
                            viewBox="0 0 16 16"

                          >
                            <path
                              d="M7 14s-1 0-1-1 1-4 5-4 5 3 5 4-1 1-1 1H7Zm4-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6Zm-5.784 6A2.238 2.238 0 0 1 5 13c0-1.355.68-2.75 1.936-3.72A6.325 6.325 0 0 0 5 9c-4 0-5 3-5 4s1 1 1 1h4.216ZM4.5 8a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5Z"></path>
                          </svg>
                        </span>
                      </div>
                    </div>

                    <div className="d-flex flex-wrap flex-fill text-center justify-content-center align-items-center">

                      {localStorage.getItem("usertype") === "Player" ? (

                        <div className="">

                          {element.disable_registration
                            ? <p className="btn btn-sm btn-no-registration m-0">
                              {t("page.tournamentsdetails.No Registration")}

                            </p>
                            : element.is_suspend
                              ? <p className="btn btn-sm btn-suspended">
                                {t("page.tournamentsdetails.Suspended")}

                              </p>
                              : element.isuser || element.iswaiting ? (
                                <>
                                  {element.players &&
                                    element.players.waiting !== 0 ? (
                                    <>
                                      <p
                                        className="btn btn-sm btn-cancel-register-tournament"
                                        // onClick={(e) =>
                                        //   tournamentCancelRegistrationFromWaiting(
                                        //     element.id,
                                        //   )
                                        // }
                                        onClick={() => {
                                          handleconfirmcancleOpenWait(); // Opens the modal
                                          setConfirmCancleIdWait(element.id); // Stores the ID for the action
                                        }}
  
                                      >
                                        {t('page.tournaments.tournamentstable.Unregister')} <br />
                                        <span className="player-waiting-text d-block">
                                          {t("page.tournamentsdetails.Waiting list")} (pos {element.players.waiting})
                                        </span>
                                      </p>
                                    </>
                                  ) : (
                                    <p
                                      className="btn btn-sm btn-cancel-register-tournament"
                                      // onClick={(e) =>
                                      //   tournamentCancelRegistration(element.id)
                                      // }

                                      onClick={() => {
                                        handleconfirmcancleOpen(); // Opens the modal
                                        setConfirmCancleId(element.id); // Stores the ID for the action
                                      }}
  
                                      
                                    >
                                      {t('page.tournaments.tournamentstable.Unregister')}
                                    </p>
                                  )}


                                  <>
                                    {element.is_late_arrival == 1 ? (
                                      element.late === "" ? (
                                        <Button
                                          className="btn btn-sm btn-primary btn-register-tournament btn-announce"
                                          onClick={() => handleannounce(element.id)}
                                        >
                                          {t("page.tournamentsdetails.Announce my late reg")}

                                        </Button>
                                      ) : (
                                        <>
                                          <div>
                                            <p className="mb-0">{t("page.tournamentsdetails.Late")}: {element.late}</p>
                                            <Link
                                              className="action-link blue-link"
                                              onClick={() =>
                                                handleannounce(element.id, element.late)
                                              }
                                            >
                                              {t("page.tournamentsdetails.Edit")}

                                            </Link>
                                            <Link
                                              className="action-link red-link mb-1"
                                              onClick={() =>
                                                handleannouncedelete(
                                                  element.detail &&
                                                  element.detail.tournament_id,
                                                )
                                              }
                                            >
                                              {t("page.tournamentsdetails.Delete")}

                                            </Link>
                                          </div>
                                        </>
                                      )
                                    ) : (
                                      ""
                                    )}
                                  </>
                                  {/* } */}
                                </>
                              ) : element.players.registered +
                                element.detail.reservedplayers ===
                                element.detail.maxplayers ? (
                                <Button
                                  className="btn btn-sm btn-primary btn-register-tournament"
                                  onClick={(e) => tournamentRegistration(element.id)}
                                >
                                  <Image
                                    src={waitingImg}
                                    width="20"
                                    height="20"
                                    fluid
                                  /> {t("page.tournamentsdetails.Waiting list")} ({element.players.waiting})
                                </Button>
                              ) : (
                                <Button
                                  className="btn btn-sm btn-primary btn-register-tournament"
                                  onClick={(e) => tournamentRegistration(element.id)}
                                >
                                  <Image
                                    src={waitingImg}
                                    width="20"
                                    height="20"
                                    fluid
                                  />
                                </Button>
                              )}
                        </div>
                      ) : localStorage.getItem("usertype") === "Room Manager" ? (
                        element.room.user_id === props.id ? (
                          <div className="">
                            <Link
                              className="btn btn-sm btn-primary btn-register-tournament check-in-btn"
                              to={`/manager/checkin-tournament/${element.id}`}
                            >
                              {t("page.tournamentsdetails.CHECK-IN")}

                            </Link>
                          </div>
                        ) : (
                          <div className="tm-action"></div>
                        )
                      ) : localStorage.getItem("usertype") === "Admin" ? (
                        <div className="">
                          <Button className="btn btn-sm btn-primary btn-register-tournament check-in-btn">
                            {t("page.tournamentsdetails.CHECK-IN")}
                          </Button>
                        </div>
                      ) : (
                        <div className="tm-action">
                          <Button
                            className="btn btn-sm btn-primary btn-register-tournament"
                            onClick={() => navigate("/login")}
                          >
                            {t("page.tournamentsdetails.Login to register")}

                          </Button>
                        </div>
                      )}

                    </div>
                  </div>

                </div>


              </React.Fragment>

              { index + 1 >= bottomBannerMobileInterval && (index + 1 - bottomBannerMobileInterval) % bottomBannerMobileInterval === 0 ? (
                <>
                  {topbannersSettings.length > 0
                    && topbannersSettings[0].adv_bottom_banner == 1
                    && topbanners.length > 0
                    ? (
                      <div className="advertising-banner">
                        <a
                          // href={topbanners[getBannerIndex(index, bottomBannerMobileStart, bottomBannerMobileInterval)]?.url}
                          // target="_blank"
                          href={`${topbanners[getBannerIndex(index, bottomBannerMobileStart, bottomBannerMobileInterval)]?.url
                            .replace(/^http:\/\//, 'https://')
                            .replace(/^https:\/\//, 'https://')
                            }`}
                          // href={`https://${topbanners[getBannerIndex(index, bottomBannerMobileStart, bottomBannerMobileInterval)]?.url}`}
                          target="_blank"
                        >
                          <img
                            className="image divImg"
                            src={zerogif}
                            width="500"
                            height="50"
                            style={{
                              backgroundImage:
                                "url(" +
                                process.env.REACT_APP_BANNER_IMAGE_URL +
                                "" +
                                topbanners[getBannerIndex(index, bottomBannerMobileStart, bottomBannerMobileInterval)]?.image +
                                ")",
                            }}
                            alt="Changing Image"
                          />
                        </a>
                      </div>
                    ) : (
                      <></>
                    )}
                </>
              ) : (
                <></>
              ) }
            </div>
          ))
        }
      </div>







      <div className="d-flex align-items-center tournaments-top justify-content-md-end">
        <Col md={7}>
          <div className="defination-of-games">
            <div className="defination-of-games-info">
              <div className="defination-games-info">
                <ul className="p-0 list-unstyled d-flex justify-content-end">
                  <li>
                    <span className="re-entry">R</span>Re-entry
                  </li>
                  <li>
                    <span className="bounty">B</span>Bounty
                  </li>
                  <li>
                    <span className="championship">Championship</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </Col>
      </div>

      <Modal show={show} onHide={handleClose} size="xl">
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body className="p-0">
          <div className="tournament-description-wrap">
            <Row className="flex-column flex-md-row">
              <Col md={12} lg={5}>
                <div className="players-Template-wrap mt-0">
                  <h3>
                    {t(
                      "page.tournaments.tournamentstable.Playersmodal.Players",
                    )}
                  </h3>
                  <div className="d-flex structure-top-wrap align-items-center flex-wrap justify-content-between">
                    <span className="text-nowrap structure-top-wrap-block text-center four-boxes">
                      <span className="structure-block-label d-block">
                        {t(
                          "page.tournaments.tournamentstable.Playersmodal.Players",
                        )}
                        :{" "}
                      </span>
                      <span className="structure-block-val d-block">
                        {tournamentDetails.players
                          ? tournamentDetails.players.registered.total +
                          tournamentDetails.reservedplayers
                          : ""}{" "}
                        /
                        {tournamentDetails.maxplayers
                          ? tournamentDetails.maxplayers
                          : ""}
                      </span>
                    </span>
                    <span className="text-nowrap structure-top-wrap-block text-center four-boxes">
                      <span className="structure-block-label d-block">
                        {t(
                          "page.tournaments.tournamentstable.Playersmodal.Waiting lists",
                        )}
                      </span>
                      <span className="structure-block-val d-block">
                        {tournamentDetails.players
                          ? tournamentDetails.players.waiting.total
                          : ""}
                      </span>
                    </span>
                    <span className="text-nowrap structure-top-wrap-block text-center four-boxes">
                      <span className="structure-block-label d-block">
                        {t(
                          "page.tournaments.tournamentstable.Playersmodal.Anonymous Players",
                        )}
                      </span>
                      <span className="structure-block-val d-block">
                        {anonymousPlayerLength}
                      </span>
                    </span>
                    <span className="text-nowrap structure-top-wrap-block text-center four-boxes">
                      <span className="structure-block-label d-block">
                        {t(
                          "page.tournaments.tournamentstable.Playersmodal.Reserved places",
                        )}
                      </span>
                      <span className="structure-block-val d-block">
                        {tournamentDetails.reservedplayers
                          ? tournamentDetails.reservedplayers
                          : 0}
                      </span>
                    </span>
                  </div>

                  {/* <Scrollbars style={{ height: 515 }}> */}
                  <PlayerList
                    data={tournamentPlayerDetails}
                    roomManagerId={tournamentDetails?.room_manager_id}
                  />
                  {/* </Scrollbars> */}

                  {/* <Scrollbars style={{ height: 515 }}>
                    <AnonymousList data={tournamentAnonymousList} />
                  </Scrollbars> */}

                  {tournamentWaitingDetail.data &&
                    tournamentWaitingDetail.data.length === 0 ? (
                    <></>
                  ) : (
                    <>
                      {tournamentWaitingDetail.length != 0 ? (
                        <h3 className="text-center mt-5">Waiting list</h3>
                      ) : (
                        ""
                      )}
                      {/* <Scrollbars style={{ height: 515 }}> */}
                      <WaitingList
                        data={tournamentWaitingDetail}
                        roomManagerId={tournamentDetails?.room_manager_id}
                      />
                      {/* </Scrollbars> */}
                    </>
                  )}
                </div>
              </Col>
              <Col md={12} lg={7} className="mt-5 mt-lg-0">
                <TournamentsStructure
                  structure={tournamentStructureDetails}
                  data={tournamentDetails}
                  title={isBreakTitle}
                />
              </Col>
            </Row>
            <Row>
              <Col className="text-center mt-3">
                <Link
                  onClick={handleCloseRedirect}
                  to={`/tournaments/${tournamentRoomSlug}/${tournamentSlug}`}
                  className="btn btn-primary load-more-btn"
                >
                  <b>
                    {t(
                      "page.tournaments.tournamentstable.Playersmodal.More infos",
                    )}
                  </b>
                </Link>
              </Col>
            </Row>
          </div>
        </Modal.Body>
        <Modal.Footer className="mb-50 d-block d-md-none text-end">
          <Link onClick={handleClose}>
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M18.9692 0L20 1.03084L1.03076 20L0 18.9699L18.9692 0Z"
                fill="#F7F5F5"
              ></path>
              <path
                d="M1.03076 0L20 18.9685L18.9692 20L0 1.03153L1.03076 0Z"
                fill="#F7F5F5"
              ></path>
            </svg>
          </Link>
        </Modal.Footer>
      </Modal>
      <Modal show={showannounce} onHide={handleannounceclose}>
        <Modal.Header>
          <Modal.Title>Saved</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Form onSubmit={handleannounceSubmit}>
            <Form.Group className="form-group" controlId="">
              <p className="description mb-10">
                Announcement Time <span className="required">*</span>
              </p>

              <Form.Control
                type="time"
                className=""
                name="timing"
                defaultValue={announceLateTime}
              />
              <p className="error">{Error}</p>
            </Form.Group>
            <Modal.Footer>
              <Button variant="primary" type="submit">
                Save
              </Button>

              <Button
                variant="secondary"
                type="reset"
                onClick={() => setShowannounce(false)}
              >
                Cancel
              </Button>
            </Modal.Footer>
          </Form>
        </Modal.Body>
      </Modal>

{/* cancel Registration Modal */}
<Modal show={confirmCancle} onHide={handleconfirmcancleclose}>
 
  <Modal.Body>
  {t(
    "page.modal.Do you want to Unregister?",
  )}
  
     {/* (ID: {confirmCancleId}) */}
  </Modal.Body>

  <Modal.Footer>
    <Button className="small-btn-mobile" onClick={() => tournamentCancelRegistration(confirmCancleId)}>
    {t(
    "page.modal.Confirm",
  )}
     
    </Button>
    <Button  className="small-btn-mobile"  onClick={handleconfirmcancleclose}  variant="secondary">
    {t(
    "page.modal.Cancel",
  )}
    
    </Button>
  </Modal.Footer>
</Modal>



{/*Waiting cancel Registration Modal */}
<Modal show={confirmCancleWait} onHide={handleconfirmcanclecloseWait}>
 
  <Modal.Body>
  {t(
    "page.modal.Do you want to Unregister?",
  )}
  
     {/* (ID: {confirmCancleId}) */}
  </Modal.Body>

  <Modal.Footer>
    <Button className="small-btn-mobile" onClick={() => tournamentCancelRegistrationFromWaiting(confirmCancleIdWait)}>
    {t(
    "page.modal.Confirm",
  )}
     
    </Button>
    <Button  className="small-btn-mobile"  onClick={handleconfirmcanclecloseWait}  variant="secondary">
    {t(
    "page.modal.Cancel",
  )}
    
    </Button>
  </Modal.Footer>
</Modal>


      <Modal show={announce}>
        <>
          <Modal.Header>
            <Modal.Title>Saved</Modal.Title>
          </Modal.Header>

          <Modal.Body>{saveMessage}</Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => {
                window.location.reload(false);
                setannounce(false);
              }}
            >
              Okay
            </Button>
          </Modal.Footer>
        </>
      </Modal>
    </>
  );
};

export default TournamentsTable;
